:root {
  --Red-Hat: 'Lato', sans-serif;
  // --Red-Hat: "Red Hat Display", sans-serif;
  // --Charlie-Kingdom: "Charlie Kingdom", sans-serif;
  --Charlie-Kingdom: 'Lato', sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none !important;
  margin: 0 !important;
}
input[type='number'] {
  // pointer-events: none;
}

.zoom {
  transition: all ease-in-out 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.font-w-600 {
  font-weight: 600 !important;
}
.primary-color {
  color: var(--primary-colors);
}
.sticky {
  position: sticky !important;
  top: 0;
}
.comments-area p {
  font-size: 14px !important;
  margin-bottom: 0;
}
.comments-area .comment-list .single-comment {
  border: none;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
}
.thumb {
  min-width: 0px !important;
}
.thumb-star {
  background-color: var(--primary-colors);
  color: #fff;
  padding: 0 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  font-size: 12px;
  // width: 20%;
}
.review-star {
  margin-left: 2px;
}

.new_cat_slider .card-2 {
  @media screen and (max-width: 426px) {
    height: 110px;
    width: 100px;
    min-height: 110px;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    figure {
      height: 70px;
    }
    img {
      border-radius: 50%;
    }
  }
}
.new_cat_slider .swiper-wrapper {
  @media screen and (max-width: 426px) {
    height: 130px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: -16px;
  right: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: -16px;
  left: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-button-next {
  top: 60%;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  color: blue;
  font-weight: 700;
  outline: 0;
  transition: background-color 0.2s ease, color 0.2s ease;

  &::after {
    font-size: 16px;
  }
}

.myNewArrow .swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.myNewArrow .swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.myNewArrow .swiper-button-prev.swiper-button-disabled,
.myNewArrow .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

/* flex */
.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: end !important;
}
.justify-between {
  justify-content: space-between !important;
}

.flex-1 {
  flex: 1 !important;
}
.sm\:align-items-end {
  @media screen and (max-width: 480px) {
    align-items: flex-end !important;
  }
}

///////@at-root
.custom_dot_line {
  transform: rotate(90deg);
  height: 32px;
  width: 100px;
}
.dot_wrapper {
  height: 100px;
  width: 32px;
}
.dot_wrapper svg {
  height: 100px;
  width: 32px;
  margin-left: -12px;
  margin-top: 5px;
}
.otp_input {
  height: 100%;
  width: 100% !important;
  border: #adb5bd solid;
  border-radius: 5px;
}

.path-vert {
  stroke-dasharray: 10;
  /*   stroke-dashoffset: 800; */
  // animation: draw1 5s linear alternate;
}

@keyframes draw1 {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 600;
  }
}
.otp_container {
  width: 55%;
}
.otp_container div {
  width: 40px;
  height: 40px;
}
.otp_container.otp_cont_2 div {
  width: 75px;
  height: 40px;
  margin: 0px 5px 0 0;
}

.otp_container input {
  padding: 0 !important;
}
.otp_focus {
  border: rgb(0, 0, 0) solid !important;
  // @media screen and (max-width:768px) {
  // border:none !important;
  // }
}
.custom_location {
  background-color: #c5ead9 !important;
  border-radius: 10px;
}
.custom_location a {
  background-color: #c5ead9 !important;
  height: 44px;
  padding: 10px !important;
}

.custom_location a img {
  margin-right: 10px !important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.g_marker {
  position: absolute;
  width: 38px;
  height: 37px;
  background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: grab;
}
.g_autoComplete {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.g_googlemap {
  width: 100%;
  height: 100%;
}
.main-map-wrapper {
  height: 100vh;
  width: 100%;
  margin-bottom: 4rem;
  filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.search_map {
  position: absolute;
  top: 10px;
  left: 10px;
  // transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}
.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border-radius: 5px;
}
.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}
.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}

.display-sm-block {
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
  }
}
.display-sm-none {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.display-lg-none {
  display: none !important;
  @media screen and (max-width: 1200px) {
    display: block !important;
  }
}
.display-md-none {
  display: block;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.positioning {
  top: 11px;
  right: 10px;
}

.list_cat {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.custom-filter-bar {
  @media screen and (max-width: 1200px) {
    position: absolute;
    background: white;
    max-width: 30%;
    min-width: 300px;
    left: 0;
    top: 0;
    padding: 2rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    z-index: 99;
    display: block !important;
    max-height: 100vh;
    height: 100vh;
    overflow-y: scroll;
  }
}

.background-cover {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
    background-color: black;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}
.bg-lightGray {
  background-color: #f4f4f4;
}
.shoplist-child {
  // min-height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
}
.shoplist {
  width: 100%;
}

.price-per {
  font-size: 14px !important;
  color: #2e9d5e !important;
  font-weight: 500;
}
.form-check-input:checked {
  background-color: #1b7834 !important;
  border-color: #8aa598 !important;
}
.display-md-block {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.md\:flex-col {
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}
.sm\:mt-1 {
  @media screen and (max-width: 480px) {
    margin-top: 1rem !important;
  }
}

.sm\:display-between {
  @media screen and (max-width: 480px) {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.sm\:m-0 {
  @media screen and (max-width: 480px) {
    margin: 0 !important;
  }
}
.sm\:p-0 {
  @media screen and (max-width: 480px) {
    padding: 0px !important;
  }
}
.sm\:p-1 {
  @media screen and (max-width: 480px) {
    padding: 10px !important;
  }
}
.sm\:px-0 {
  @media screen and (max-width: 480px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.sm\:pt-2 {
  @media screen and (max-width: 480px) {
    padding-top: 1.5rem !important;
  }
}
.sm\:p-2 {
  @media screen and (max-width: 480px) {
    padding: 1.5rem !important;
  }
}
.sm\:p-3 {
  @media screen and (max-width: 480px) {
    padding: 2rem !important;
  }
}
.sm\:d-flex {
  @media screen and (max-width: 480px) {
    display: flex !important;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.cart_total_label {
  @media screen and (max-width: 480px) {
    text-align: start !important;
  }
}
.sm\:w-fit {
  @media screen and (max-width: 480px) {
    width: fit-content !important;
  }
}
.install_app_container {
  @media screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.col-xs-6 {
  @media screen and (max-width: 480px) {
    width: 50% !important;
  }
}
.mob_detail_extralink .detail-qty {
  padding: 5px 7px 5px 14px !important;
  max-width: 77px !important;
  @media screen and (max-width: 480px) {
    // padding: 5px !important;
    // max-width: 100% !important;
    padding: 5px 45px 5px 20px !important;
    margin-bottom: 5px !important;
  }
}
.custom_collection_sidebar .image {
  height: 40px !important;
  width: 40px !important;
}

//////////////. PAGES STYLES ////////////////////

.floting-whatsapp-icon {
  position: fixed;
  right: 10px;
  height: 50px;
  width: 50px;
  bottom: 85px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  svg {
    font-size: 35px;
    color: #25d366;
    animation: beat 0.35s infinite alternate 2s;
    transform-origin: center;
  }
  @keyframes beat {
    to {
      transform: scale(1.2);
    }
  }
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.bg-element {
  right: 0;
  top: 0;
  width: 700px;
  height: 700px;
  z-index: -1;
  @media screen and (max-width: 768px) {
    right: -30px;
    top: -30px;
    width: 400px;
    height: 500px;
  }
  @media screen and (max-width: 480px) {
    right: -140px;
    width: 400px;
    height: 500px;
  }
}
.header-wrapper {
  @media screen and (max-width: 480px) {
    justify-content: space-between !important;
  }
}
.page-nav-link {
  a {
    font-family: var(--Charlie-Kingdom);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 0 30px;
    &.red {
      color: rgba(255, 0, 65, 1);
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}
.highlight {
  .content {
    width: 40%;
    h4 {
      font-family: var(--Charlie-Kingdom);
      font-weight: 400;
      font-size: 55.5556px;
      line-height: 102%;
      letter-spacing: -0.01em;
      color: #22b3be;
      margin-bottom: 10px;
    }
    p {
      font-family: var(--Red-Hat);
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      color: #5f5f5f;
    }
    .product-price {
      .font-head-new {
        font-family: var(--Charlie-Kingdom);
        font-weight: 400;
        font-size: 38.3537px;
        @media screen and (max-width: 768px) {
          font-size: 29.9856px;
        }
        line-height: 46px;
        color: #007551;
      }
      .old-price-new {
        font-family: var(--Charlie-Kingdom);
        font-weight: 400;
        font-size: 20.8303px;
        @media screen and (max-width: 768px) {
          font-size: 16.2855px;
        }
        line-height: 23px;
        color: #252928;
      }
      .price-per-new {
        font-family: var(--Red-Hat);
        font-weight: 900;
        font-size: 17.2197px;
        line-height: 23px;
        @media screen and (max-width: 768px) {
          font-size: 13.4627px;
        }
        letter-spacing: -0.03em;
        color: #007551;
      }
    }
    .btn-custom {
      margin: 15px 0;
      background: #ff0041;
      border-radius: 7.14894px;
      width: 200px;
      height: 42px;
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }
  .image-wrapper {
    width: 60%;
    height: 500px;
    img {
      object-fit: contain;
    }
    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    .content {
      width: 100% !important;
      padding: 30px 10px;
      h4 {
        font-size: 43.4343px;
      }
      p {
        font-size: 13.2909px;
      }
      .btn-custom {
        width: 140px;
        height: 32.84px;
      }
    }
    .image-wrapper {
      width: 100% !important;
      // margin-bottom: 4rem;
      // img {
      //   transform: translateX(20px);
      // }
    }
  }
}
.video_banner {
  margin-top: 4rem;
  h4 {
    font-family: var(--Charlie-Kingdom);
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #22b3be;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 25.4425px;
    }
  }
  .react-player {
    // margin: 6rem 0;
    position: relative;
    width: fit-content;
    margin: auto;
    margin-bottom: 10rem;
    margin-top: 10rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem;
      margin-top: 3rem;
      width: 100%;
      height: 300px;
      .react-player-video {
        width: 100%;
        height: 100%;
        div {
          width: 100% !important;
        }
      }
    }
    .el {
      position: absolute;
      z-index: 0;
      &.el1 {
        right: -60px;
        bottom: -70px;
        @media screen and (max-width: 768px) {
          width: 81.9px;
          height: 81.9px;
          right: -30px;
          bottom: -30px;
          display: none;
        }
      }
      &.el2 {
        left: -70px;
        top: -40px;
        z-index: 2;
        @media screen and (max-width: 768px) {
          width: 71.77px;
          height: 71.77px;
          left: -30px;
          top: -20px;
          display: none;
        }
      }
      &.el3 {
        right: -65px;
        bottom: 90px;
        @media screen and (max-width: 768px) {
          width: 25.33px;
          height: 25.33px;
          right: -30px;
          bottom: 55px;
          display: none;
        }
      }
      &.el4 {
        left: -50px;
        top: 110px;
        @media screen and (max-width: 768px) {
          width: 16.89px;
          height: 16.89px;
          left: -25px;
          top: 55px;
          display: none;
        }
      }
    }
    .react-player-video {
      z-index: 1;
      iframe {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          // border-radius: 21.5311px;
          border-radius: 0;
        }
      }
      .react-player__preview {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          // border-radius: 21.5311px;
          border-radius: 0;
        }
      }
    }
    .ytp-chrome-top {
      display: none !important;
    }
  }
}
.shortContent {
  // margin-top: 2rem;
  // margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 80px 0;
  .shortContentInner {
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .content {
    max-width: 335px;
    @media screen and (max-width: 768px) {
      justify-content: unset;
      margin-bottom: 20px;
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 15px;
      @media screen and (max-width: 768px) {
        width: 90px;
        height: 90px;
      }
    }
    h4 {
      font-family: var(--Charlie-Kingdom);
      font-weight: 400;
      font-size: 20.8942px;
      line-height: 23px;
      color: #ffffff;
      white-space: inherit;
    }
  }
}

.wideContent {
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    flex-direction: row-reverse;
  }
  &.right {
    flex-direction: row;
  }
  &.top {
    flex-direction: column-reverse;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 500px;
      img {
        max-height: 500px;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 768px) {
      .contentImage {
        max-height: 500px;
        img {
          max-height: 500px;
        }
      }
    }
  }
  &.bottom {
    flex-direction: column;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
  .content {
    width: 50%;
    margin: 8rem auto;
    flex-direction: column;
    display: flex;
    // align-items: center;
    justify-content: center;
    &.withImg {
      width: 50%;
      padding: 0 80px;
      @media screen and (max-width: 768px) {
        padding: 0;
        width: 90%;
      }
    }
    h4 {
      font-family: var(--Charlie-Kingdom);
      font-weight: 400;
      font-size: 38.5939px;
      line-height: 116.5%;
      letter-spacing: -0.01em;
      // padding-bottom: 20px;
    }
    p {
      font-family: var(--Red-Hat);
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      // text-align: center;
      color: #5f5f5f;
    }
    button {
      height: 42px;
      background: #ff0041;
      border-radius: 7.14894px;
      margin-bottom: 10px;
      margin-top: 15px;
      width: fit-content;
    }
    @media screen and (max-width: 768px) {
      margin: 2rem auto;
      width: 80%;
      h4 {
        font-size: 25.44px;
      }
      p {
        font-size: 13.2px;
      }
      button {
        font-size: 13px;
      }
    }
  }

  .contentImage {
    width: auto;
    height: auto;
    max-height: 600px;
    img {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse !important;
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
}

.image-grid {
  padding: 4rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 0;
    .gridItem {
      height: auto !important;
      max-height: 100% !important;
    }
  }
  .gridItem {
    // padding: 10px;
    max-height: 570px;
    height: auto;
    // margin-bottom: 10px;
    img {
      // object-fit: cover;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}

.testimonials {
  margin: 0 5rem;
  @media screen and (max-width: 768px) {
    margin: 0;
    margin-top: 5rem;
  }
  h4 {
    font-family: var(--Charlie-Kingdom);
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    letter-spacing: -0.01em;
    color: #22b3be;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: 26.6836px;
      text-align: center;
      width: 80%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .accordion-header {
    font-family: var(--Charlie-Kingdom);
    font-weight: 400;
    font-size: 18.8867px;
    line-height: 21px;
    text-transform: capitalize;
    color: #ffffff;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
  }
  .accordion-item {
    background-color: #f4f4f4;
    margin-bottom: 10px;
  }
  .accordion-button {
    border: none;
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
  .accordion-collapse {
    padding: 10px;
  }
  .accordion-button:not(.collapsed) {
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
    color: #fff;
    background-color: #22b3be;
    // .accordion-button:not(.collapsed)::after {
    //   background-image: url("../imgs/landing/icons/arrowUp.png") !important;
    // }
  }
  .accordion-body {
    font-family: var(--Red-Hat);
    font-weight: 400;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
    line-height: 25px;
    color: #000000;
  }
}

.customerReview {
  .swiper-slide-active {
    .review-slide {
      background: rgba(123, 0, 99, 0.06);
      border-radius: 73px;
      transform: scale(1);
      @media screen and (max-width: 768px) {
        border-radius: 40px;
      }
    }
  }
  margin-top: 5rem;
  margin-bottom: 5rem;
  h4 {
    font-family: var(--Charlie-Kingdom);
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #22b3be;
    margin: 0 8rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 768px) {
      font-size: 38.5939px;
      text-align: center !important;
      width: 84%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .review-slide {
    background: rgba(244, 244, 244, 0.65);
    border-radius: 35.0962px;
    padding: 50px;
    text-align: center;
    transform: scale(0.788);
    transition: all ease-in-out 0.3s;
    img {
      width: 70px;
      height: 70px;
    }
    p {
      font-family: var(--Red-Hat);
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }
    h4 {
      font-family: var(--Charlie-Kingdom);
      font-weight: 400;
      font-size: 22.4842px;
      line-height: 25px;
      text-transform: capitalize;
      color: #1e1e1e;
    }
    @media screen and (max-width: 768px) {
      h4 {
        font-size: 13.5px;
      }
      p {
        font-size: 13px;
      }
      .review-slide {
        padding: 20px !important;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .slider-arrow {
    display: none;
  }
}

.footer {
  margin-top: 3rem;
  // background-color: #ff0041;
  background-color: #5598a7;
  color: #fff;
  padding: 80px;
  img {
    width: 220px;
    height: 156px;
  }
  p {
    font-family: var(--Red-Hat);
    font-weight: 700;
    font-size: 14px;
    line-height: 161.3%;
    color: #ffffff;
  }
  .footer-link-widget {
    h3 {
      font-family: var(--Charlie-Kingdom);
      font-weight: 400;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
    }
    ul {
      li {
        a {
          font-family: var(--Red-Hat);
          font-weight: 400;
          font-size: 14px;
          line-height: 153.3%;
          color: #ffffff;
        }
      }
    }
  }
  .mobile-social-icon a {
    background-color: transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  @media screen and (max-width: 768px) {
    padding: 80px 30px;
    .logos {
      flex-direction: column;
      margin-bottom: 40px;
      align-items: flex-start !important;
    }
    p {
      width: 100%;
    }
    img {
      width: 130px;
      height: 92px;
      &.logo-footer {
        margin-left: -15px;
      }
    }
    .footer-link-widget {
      h3 {
        font-size: 24.8193px;
      }
    }
  }
}

.tada-animation {
  animation-name: tada;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: both;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.related-product-wrap {
  .section-title {
    font-family: var(--Charlie-Kingdom);
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #22b3be;
    margin-bottom: 20px;
  }
}

.mobileCarousel {
  @media screen and (max-width: 768px) {
    .mobileContainer {
      margin: 0 !important;
      border-radius: 0 !important;

      .mobileBanner {
        border-radius: 0 !important;
        height: 100% !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.section-wrapper {
  &.web {
    display: unset;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: unset;
    }
  }
  &.common {
  }
}
.related-product-wrap {
  .related-head {
    font-family: var(--Charlie-Kingdom);
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    letter-spacing: -0.01em;
    color: #22b3be;
    margin: 0 8rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 768px) {
      font-size: 38.5939px;
      text-align: center;
      width: 90%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
}

.custom-cart-button-group {
  border: 1px solid #dadee2;
  min-height: 150px;
  height: auto;
  border-radius: 20px;
  background-color: #f4f6fa;
  // padding: 1rem;
  margin-bottom: 2rem;
  .chat-w-exp {
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
  }
  .buttons {
    border-bottom: 1px solid #dadee2;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .border-r-1 {
      border-right: 1px solid #dadee2;
    }
  }
}

.footerAddress {
  margin-left: 3rem;
  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
}

.review-modal {
  @media screen and (max-width: 768px) {
    .myDeal-body {
      width: 100%;
      padding: 1rem !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .comment-form {
        width: 100%;
      }
    }
    .review-product {
      background: aliceblue;
      padding: 10px;
    }
    .review-product-list figure {
      margin-bottom: 0px !important;
    }
  }
}

.delete-modal {
  .modal-content {
    background-clip: unset;
  }
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.modal-scroll-div {
  max-height: 630px;
  overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  // &::-webkit-scrollbar-track {
  //   border-radius: 8px;
  //   background-color: #95a5a6;
  //   border: 1px solid #cacaca;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 8px;
  //   background-color: var(--primary-colors);
  // }
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 80px);
    height: calc(90vh - 80px);
  }
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 80px);
    height: calc(100vh - 80px);
  }
}
.cod-confirm-modal-image {
  height: 250px;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 170px;
    width: 170px;
  }
}
.cod-confirm-modal-image {
  height: 50px;
  width: 50px;
}
.popup-confirm-button {
  min-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 65%;
  }
}
//////////////. PAGES STYLES ////////////////////
